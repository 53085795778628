'use client';

import {
	faCalendar,
	faFaceFrown,
	faLayerGroup,
	faLongArrowRight,
	faSearch,
	faSitemap,
	faSpinnerThird,
	faTicketSimple,
	faUsers
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, InputField } from '@pickleballinc/react-ui';
import moment from 'moment';
import Image from 'next/image';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { GlobalSearchNewsInterface, GlobalSearchResult, SearchRes, TournamentSummary } from '../utils/types';
import CustomLink from '../../ui/custom-link';
import { Application } from '../utils/nav.config';

type GlobalSearchButtonResponse = {
	DisplayOrder: string;
	IsEnabled: boolean;
	Title: string;
	URL: string | null;
};

export type GlobalSearchButtonsResponse = {
	payload: GlobalSearchButtonResponse[];
};

interface GlobalSearchProps {
	searchVisible: boolean;
	globalSearchButtons: GlobalSearchButtonsResponse;
	toggleRef?: React.MutableRefObject<any>;
	onHide?: (visible: boolean) => void;
	isClubs: boolean;
	pickleballHref: string;
	tournamentsHref: string;
	clubsHref: string;
	teamLeaguesHref: string;
	leaguesHref: string;
	application: Application;
}

export default function GlobalSearch({
	searchVisible,
	globalSearchButtons,
	toggleRef,
	onHide,
	isClubs,
	pickleballHref,
	tournamentsHref,
	clubsHref,
	teamLeaguesHref,
	leaguesHref,
	application
}: GlobalSearchProps) {
	const [searchTerm, setSearchTerm] = useState('');
	const [error, setError] = useState('');
	const [searchData, setSearchData] = useState<SearchRes>({
		leagues: [],
		clubs: [],
		teamLeagues: [],
		tourneys: [],
		players: [],
		news: []
	});
	const [isFetching, setIsFetching] = useState(false);

	const containerRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);

	const handleClickOutside = useCallback(
		(e: any) => {
			if (containerRef.current && !containerRef.current.contains(e.target) && !toggleRef?.current?.contains(e.target)) {
				if (onHide) {
					onHide(false);
				}
			}
		},
		[onHide, toggleRef]
	);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		if (searchVisible && inputRef.current) {
			inputRef.current.focus();
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [searchVisible, handleClickOutside]);

	const fetchSearchData = async (term: string) => {
		setIsFetching(true);
		try {
			const response = await fetch(`/api/${isClubs ? '' : 'v1/'}search?query=${term}`);
			const result: { data: SearchRes; statusCode: number; error?: string } = await response.json();
			if (result.error) {
				setError(error);
				setSearchData({ leagues: [], clubs: [], teamLeagues: [], tourneys: [], players: [], news: [] });
			} else if (result.data) {
				setError('');
				setSearchData(result.data || { leagues: [], clubs: [], teamLeagues: [], tourneys: [], players: [], news: [] });
			}
		} catch (error) {
			console.error('Error fetching search data:', error);
			setError('Something went wrong, try searching something else.');
			setSearchData({ leagues: [], clubs: [], teamLeagues: [], tourneys: [], players: [], news: [] });
		} finally {
			setIsFetching(false);
		}
	};

	useEffect(() => {
		if (searchTerm.length > 2) {
			const timeoutId = setTimeout(() => {
				fetchSearchData(searchTerm);
			}, 300);
			return () => clearTimeout(timeoutId);
		}
		return () => {};
	}, [searchTerm]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(e.target.value);
	};

	const getBorderColorClass = () => {
		switch (application) {
		  case 'tournament':
			return '!border-blue-500';
		  case 'team-leagues':
			return '!border-orange-500';
		  case 'wpr-rankings':
			return '!border-[#4CA30D]';
		  default:
			return '!border-experiment';
		}
	  };

	return (
		<div className="absolute top-full z-50 w-full bg-white p-4 shadow-md" ref={containerRef}>
			<div className="mx-auto max-w-[1200px] px-2">
				<div className="relative">
					<InputField
						type="text"
						className={`w-full !rounded-none !border-0 !border-b !shadow-[0_0_0_0_transparent] !transition-none focus:!outline-none ${getBorderColorClass()}`}
						placeholder="Where to go?"
						autoFocus
						onChange={(e) => handleChange(e)}
						ref={inputRef}
					/>
					<div className="absolute inset-y-0 right-0 flex items-center px-2">
						<FontAwesomeIcon icon={faSearch} size="sm" />
					</div>
				</div>
				<div className="mt-4 text-sm font-medium text-gray-800">Suggested searches</div>
				<div className="mt-1 flex flex-wrap items-center gap-1 bg-white pb-2">
					{globalSearchButtons?.payload
						.filter((item) => item.IsEnabled)
						.map((button) => {
							return (
								<CustomLink
									href={`${button.URL}`}
									key={button.Title}
									className="cursor-pointer rounded-full bg-black px-3 py-1 text-xs font-medium text-white hover:bg-black/80"
								>
									{button.Title}
								</CustomLink>
							);
						})}
				</div>

				{isFetching ? (
					<div className="flex items-center justify-center py-6 text-sm text-gray-600">
						<div className="block">
							<FontAwesomeIcon icon={faSpinnerThird} spin />
						</div>
						<span className="ml-2">Please wait...</span>
					</div>
				) : error ? (
					<div className="mt-4 flex items-center justify-center py-4 text-sm text-gray-400">
						<FontAwesomeIcon icon={faFaceFrown} className="mr-2 block text-lg" />
						No results found. Try refining your search.
					</div>
				) : searchTerm.length > 2 && searchData ? (
					<DisplayResults
						onHide={() => (onHide ? onHide(false) : {})}
						data={searchData}
						searchTerm={searchTerm}
						pickleballHref={pickleballHref}
						tournamentsHref={tournamentsHref}
						clubsHref={clubsHref}
						teamLeaguesHref={teamLeaguesHref}
						leaguesHref={leaguesHref}
					/>
				) : null}
			</div>
		</div>
	);
}

interface DisplayResultsProps {
	data: SearchRes;
	onHide?: () => void;
	searchTerm: string;
	pickleballHref: string;
	tournamentsHref: string;
	clubsHref: string;
	teamLeaguesHref: string;
	leaguesHref: string;
}

const DisplayResults = ({
	data,
	onHide,
	searchTerm,
	pickleballHref,
	tournamentsHref,
	clubsHref,
	teamLeaguesHref,
	leaguesHref
}: DisplayResultsProps) => {
	const { leagues, clubs, teamLeagues, tourneys, players, news } = data;

	if (leagues?.length === 0 && clubs?.length === 0 && tourneys?.length === 0 && players?.length === 0 && news.length === 0) {
		return (
			<div className="mt-4 flex items-center justify-center py-4 text-sm text-gray-400">
				<FontAwesomeIcon icon={faFaceFrown} className="mr-2 block text-lg" />
				No results found. Try refining your search.
			</div>
		);
	}

	const pickleballBaseURL = pickleballHref ? `http${pickleballHref?.includes('localhost') ? '' : 's'}://${pickleballHref}` : '';
	const tournamentsBaseURL = tournamentsHref || '';
	const clubsBaseURL = clubsHref || '';
	const teamLeaguesBaseURL = teamLeaguesHref || '';

	return (
		<div className="global-search-scroll max-h-80 overflow-y-auto py-4 pl-4">
			{news.length > 0 && (
				<>
					<div className="mb-1 flex w-full text-xs font-medium uppercase tracking-wider opacity-60">
						<span className="font-bold">News</span>
						<span className="ml-4">|</span>
						<CustomLink
							onClick={() => onHide && onHide()}
							href={`${pickleballBaseURL}/news/all`}
							className="mx-4 flex items-center font-bold text-experiment underline decoration-experiment underline-offset-4"
						>
							See all
							<FontAwesomeIcon icon={faLongArrowRight} className="ml-1" />
						</CustomLink>
					</div>
					{news.map((newsArticle: GlobalSearchNewsInterface) => (
						<div onClick={() => onHide && onHide()} className="m-0" key={newsArticle.slug}>
							<CustomLink
								href={`${pickleballBaseURL}/${newsArticle.categorySlug}/${newsArticle.slug}`}
								className="flex items-center py-1 text-sm hover:bg-gray-200"
							>
								{newsArticle.imageUrl ? (
									<Image
										width={64}
										height={25.6}
										className="mr-2 border border-experiment"
										src={newsArticle.imageUrl}
										alt={newsArticle.imageAltText}
									/>
								) : (
									<div className="icon mr-2 flex size-8 items-center justify-center rounded-full border border-experiment">
										<FontAwesomeIcon icon={faLayerGroup} className="text-experiment" />
									</div>
								)}
								<div className="ml-2">
									<p className="line-clamp-1">{newsArticle.title}</p>
									<div className="flex items-center">
										<FontAwesomeIcon icon={faCalendar} className="relative mr-1 mt-[-3px] block text-sm opacity-70" />
										<span className="mr-4 flex items-center text-xs uppercase opacity-60">
											{new Intl.DateTimeFormat('en-US', {
												timeZone: 'America/New_York',
												month: 'short',
												day: '2-digit',
												year: 'numeric'
											}).format(new Date(newsArticle.publishDateDisplayed))}{' '}
											{new Intl.DateTimeFormat('en-US', {
												timeZone: 'America/New_York',
												hour: '2-digit',
												minute: '2-digit',
												hour12: true
											}).format(new Date(newsArticle.publishDateDisplayed))}{' '}
											ET
										</span>
									</div>
									<span className="block text-sm opacity-60">{newsArticle.authorFullName}</span>
								</div>
							</CustomLink>
						</div>
					))}
				</>
			)}
			{tourneys?.length > 0 && (
				<>
					<div className="mb-1 mt-4 flex w-full text-xs font-medium uppercase tracking-wider opacity-60">
						<span className="font-bold">Tournaments</span>
						<span className="ml-4">|</span>
						<CustomLink
							href={`${tournamentsBaseURL}/search${searchTerm ? `?keyword=${searchTerm}` : ''}`}
							className="mx-4 flex items-center font-bold text-experiment underline decoration-experiment underline-offset-4"
						>
							See all
							<FontAwesomeIcon icon={faLongArrowRight} className="ml-1" />
						</CustomLink>
					</div>
					{tourneys.map((tourney: TournamentSummary) => {
						return (
							<div className="m-0 pr-2" key={tourney.TournamentID}>
								<CustomLink
									href={`${tournamentsBaseURL}/tournaments/${tourney.slug || tourney.TournamentID}`}
									className="flex items-center py-1 text-sm hover:bg-gray-200"
								>
									{tourney.Logo ? (
									<div className='!w-16 !max-w-16 !h-[25.6px] !max-h-[25.6px] overflow-hidden'>
										<Image
											width={64}
											height={25.6}
											className="mr-2 !h-[25.6px] object-contain border border-experiment"
											src={tourney.Logo}
											alt={tourney.Logo}
										/>
									</div>
								) : (
									<div className="icon mr-2 flex size-8 items-center justify-center rounded-full border border-experiment">
										<FontAwesomeIcon icon={faSitemap} className="relative -left-px -rotate-90 text-experiment" />
									</div>
								)}
									<div className="ml-2">
										<span className="line-clamp-2">{tourney.Title}</span>
										<div className="flex items-center">
											<FontAwesomeIcon icon={faTicketSimple} className="mr-1 block text-sm opacity-70" />
											<span className="mr-4 flex items-center text-xs uppercase opacity-60">
												{`${moment(tourney.RegistrationDateOpen).format('MMM DD')} - ${moment(tourney.RegistrationDateClosed).format('MMM DD')}`}
											</span>
											<FontAwesomeIcon icon={faCalendar} className="relative mr-1 mt-[-3px] block text-sm opacity-70" />
											<span className="flex items-center text-xs uppercase opacity-60">
												{`${moment(tourney.TourneyFromDate).format('MMM DD')} - ${moment(tourney.TourneyToDate).format('MMM DD')}`}
											</span>
										</div>
										<div className="block text-sm opacity-60">
											{`${tourney.LocationCity ? `${tourney.LocationCity}, ` : ''}${tourney.LocationState} - ${tourney.LocationCountry}`}
										</div>
									</div>
								</CustomLink>
							</div>
						);
					})}
				</>
			)}
			{leagues.length > 0 && (
				<>
					<div className="mb-1 mt-4 flex w-full text-xs font-medium uppercase tracking-wider opacity-60">
						<span className="font-bold">Leagues</span>
						<span className="ml-4">|</span>
						<CustomLink
							href={`${leaguesHref}/leagues${searchTerm ? `?keyword=${searchTerm}` : ''}`}
							className="mx-4 flex items-center font-bold text-experiment underline decoration-experiment underline-offset-4"
						>
							See all
							<FontAwesomeIcon icon={faLongArrowRight} className="ml-1" />
						</CustomLink>
					</div>
					{leagues.map((item: GlobalSearchResult) => {
						return (
							<div className="m-0" key={item.ID}>
								<CustomLink
									href={`${leaguesHref}/leagues/${item.slug || item.ID}`}
									className="flex items-center py-1 text-sm hover:bg-gray-200"
								>
									<div className="icon mr-2 flex size-8 items-center justify-center rounded-full border border-experiment">
										<FontAwesomeIcon icon={faLayerGroup} className="text-experiment" />
									</div>
									<div className="ml-2">
										{item.Title}
										{item.SubTitle2 && (
											<div className="flex items-center">
												<FontAwesomeIcon icon={faCalendar} className="relative mr-1 mt-[-3px] block text-sm opacity-70" />
												<span className="mr-4 flex items-center text-xs uppercase opacity-60">{item.SubTitle2}</span>
											</div>
										)}
										<span className="block text-sm opacity-60">{item.SubTitle}</span>
									</div>
								</CustomLink>
							</div>
						);
					})}
				</>
			)}
			{clubs.length > 0 && (
				<>
					<div className="mb-1 mt-4 flex w-full text-xs font-medium uppercase tracking-wider opacity-60">
						<span className="font-bold">Clubs</span>
						<span className="ml-4">|</span>
						<CustomLink
							href={`${clubsBaseURL}${searchTerm ? `?search=${searchTerm}` : ''}`}
							className="mx-4 flex items-center font-bold text-experiment underline decoration-experiment underline-offset-4"
						>
							See all
							<FontAwesomeIcon icon={faLongArrowRight} className="ml-1" />
						</CustomLink>
					</div>
					{clubs.map((item: GlobalSearchResult) => {
						return (
							<div className="m-0" key={item.ID}>
								<CustomLink
									href={`${clubsBaseURL}/clubs/${item.slug || item.ID}`}
									className="flex items-center py-1 text-sm hover:bg-gray-200"
								>
									<div className="icon mr-2 flex size-8 items-center justify-center rounded-full border border-experiment">
										<FontAwesomeIcon icon={faUsers} className="text-experiment" />
									</div>
									<div className="ml-2">
										{item.Title}
										{item.SubTitle2 && (
											<div className="flex items-center">
												<span className="mr-4 flex items-center text-xs uppercase opacity-60">{item.SubTitle2}</span>
											</div>
										)}
										<span className="block text-sm opacity-60">{item.SubTitle}</span>
									</div>
								</CustomLink>
							</div>
						);
					})}
				</>
			)}
			{teamLeagues && teamLeagues.length > 0 && (
				<>
					<div className="mb-1 mt-4 flex w-full text-xs font-medium uppercase tracking-wider opacity-60">
						<span className="font-bold">Team Leagues</span>
						<span className="ml-4">|</span>
						<CustomLink
							href={`${teamLeaguesBaseURL}/search${searchTerm ? `?search=${searchTerm}` : ''}`}
							className="mx-4 flex items-center font-bold text-experiment underline decoration-experiment underline-offset-4"
						>
							See all
							<FontAwesomeIcon icon={faLongArrowRight} className="ml-1" />
						</CustomLink>
					</div>
					{teamLeagues.map((item: GlobalSearchResult) => {
						return (
							<div className="m-0" key={item.ID}>
								<CustomLink
									href={`${teamLeaguesBaseURL}/team-leagues/${item.slug || item.ID}`}
									className="flex items-center py-1 text-sm hover:bg-gray-200"
								>
									{item.Image ? (
									<div className='!w-16 !max-w-16 !h-[25.6px] !max-h-[25.6px] overflow-hidden'>
										<Image
											width={64}
											height={25.6}
											className="mr-2 !h-[25.6px] object-contain border border-experiment"
											src={item.Image}
											alt={item.Image}
										/>
									</div>
								) : (
									<div className="icon mr-2 flex size-8 items-center justify-center rounded-full border border-experiment">
										<FontAwesomeIcon icon={faUsers} className="text-experiment" />
									</div>
								)}
									<div className="ml-2">
										{item.Title}
										{item.SubTitle2 && (
											<div className="flex items-center">
												<span className="mr-4 flex items-center text-xs uppercase opacity-60">{item.SubTitle2}</span>
											</div>
										)}
										<span className="block text-sm opacity-60">{item.SubTitle}</span>
									</div>
								</CustomLink>
							</div>
						);
					})}
				</>
			)}
			{players.length > 0 && (
				<>
					<div className="mb-1 mt-4 flex w-full text-xs font-medium uppercase tracking-wider opacity-60">
						<span className="font-bold">Players</span>
						<span className="ml-4">|</span>
						<CustomLink
							href={`${pickleballBaseURL}/players${searchTerm ? `?search=${searchTerm}` : ''}`}
							className="mx-4 flex items-center font-bold text-experiment underline decoration-experiment underline-offset-4"
						>
							See all
							<FontAwesomeIcon icon={faLongArrowRight} className="ml-1" />
						</CustomLink>
					</div>
					{players.map((item: GlobalSearchResult) => {
						return (
							<div className="m-0" key={item.ID}>
								<CustomLink
									href={`${pickleballBaseURL}/players/${item.slug || item.ID}`}
									className="flex items-center py-1 text-sm hover:bg-gray-200"
								>
									<div className="icon mr-2 flex size-8 items-center justify-center rounded-full border border-experiment">
										<Avatar size="sm" imageUrl={item.Image} />
									</div>
									<div className="ml-2">
										{item.Title}
										<span className="block text-sm opacity-60">{item.SubTitle}</span>
									</div>
								</CustomLink>
							</div>
						);
					})}
				</>
			)}
		</div>
	);
};
