'use client';

import { faBars, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navbar, NavbarItem } from '@pickleballinc/react-ui';
import { Menu } from '@pickleballinc/react-ui';
import { usePathname } from 'next/navigation';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import GlobalSearch, { GlobalSearchButtonsResponse } from './GlobalSearch';
import { Nav } from './Nav';
import NotificationsPopupMobile from '../Notification/NotificationsPopupMobile';
import { UserProfile } from '../UserProfile';
import { BLOG_LABEL } from '../utils/constants';
import { Application, getNavItems, getSwitcherOptions } from '../utils/nav.config';
import { NewsCategoryShortInterface, NotificationLookup, User } from '../utils/types';
import CustomLink from '../../ui/custom-link';
import HeaderLogo from './HeaderLogo';
import clsx from 'clsx';

interface MainHeaderProps {
	user: User | undefined;
	searchButtons: GlobalSearchButtonsResponse;
	application: Application;
	categories: NewsCategoryShortInterface[];
	notifications: NotificationLookup | undefined;
	canAccessManage: boolean;
	pickleballHref: string;
	tournamentsHref: string;
	clubsHref: string;
	teamLeaguesHref?: string;
	leaguesHref: string;
	wprHref?: string;
	ssoLink: string;
	checkoutLink: string;
	cdnUrl: string;
	showTLItem?: boolean;
	showWPRItem?: boolean;
}

const validBlogPathname = /^\/[a-zA-Z0-9-]+\/[a-zA-Z0-9-]+$/;

export default function MainHeader({
	user,
	searchButtons,
	application,
	categories,
	notifications,
	canAccessManage,
	pickleballHref,
	tournamentsHref,
	clubsHref,
	teamLeaguesHref = '',
	leaguesHref,
	wprHref,
	ssoLink,
	checkoutLink,
	cdnUrl,
	showTLItem,
	showWPRItem
}: MainHeaderProps) {
	const [searchShown, setSearchShown] = useState(false);
	const pathname = usePathname();
	const [mobileMenuOpened, setMobileMenuOpened] = React.useState(false);
	const searchRef = useRef(null);
	const [baseURL, setBaseURL] = useState('');
	const [isOpen, setIsOpen] = useState(false);

	const toggleDropdown = () => setIsOpen(!isOpen);

const timeoutRef = useRef<NodeJS.Timeout | null>(null);

const closeDropdown = () => {
	timeoutRef.current = setTimeout(() => {
    	setIsOpen(false);
  	}, 200);
};

const openDropdown = () => {
	if (timeoutRef.current) {
	  clearTimeout(timeoutRef.current);
	}
	setIsOpen(true);
  };

	const [headerHeight, setHeaderHeight] = useState(0);
	const headerRef = useRef<HTMLDivElement | null>(null);
	
	useEffect(() => {
		const updateHeight = () => {
		  if (headerRef.current) {
			const headerRect = headerRef.current.getBoundingClientRect();
			setHeaderHeight(headerRect.bottom);
		  }
		};
	  
		updateHeight();
	  
		window.addEventListener('resize', updateHeight);
		window.addEventListener('scroll', updateHeight, { passive: true });
	  
		return () => {
		  window.removeEventListener('resize', updateHeight);
		  window.removeEventListener('scroll', updateHeight);
		};
	  }, []);

	useEffect(() => {
		setBaseURL(window.location.href);
	}, []);

	const onRouteChangeStart = () => {
		setMobileMenuOpened(false);
	};

	useEffect(() => {
		onRouteChangeStart();
	}, [pathname]);

	useEffect(() => {
		if (mobileMenuOpened) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.removeProperty('overflow');
		}
	}, [mobileMenuOpened]);

	const isPickleball = application === 'pickleball' || application === 'wpr-rankings' || application === 'leagues';
	const isTournament = application === 'tournament';
	const isClubs = application === 'clubs';
	const isTeamLeagues = application === 'team-leagues';
	const isWPR = application === 'wpr-rankings';

	const isLocalHost = isPickleball
		? pickleballHref?.includes('localhost')
		: isClubs
			? clubsHref?.includes('localhost')
			: isTeamLeagues
				? teamLeaguesHref?.includes('localhost')
				: isTournament
					? tournamentsHref?.includes('localhost')
					: false;

	const origin = typeof window !== "undefined" ? window.location.origin : `https://${baseURL}`;
	const fullUrl = `${origin}${pathname}`;
	const resolvedApplication = application === 'pickleball' ?
		isLocalHost ?
			fullUrl.includes('leagues') ?
				'leagues'
			: application
		: fullUrl.includes(leaguesHref) ?
			'leagues'
		: application
	: application

	const pickleballPrefix = `http${isPickleball && isLocalHost ? '' : 's'}://`;

	const baseOrigin = isLocalHost
		? ''
		: baseURL.includes(`${leaguesHref}`)
			? `${leaguesHref}`
			: baseURL.includes(`${clubsHref}`)
				? `${clubsHref}`
				: baseURL.includes(`${teamLeaguesHref}`)
					? `${teamLeaguesHref}`
					: baseURL.includes(`${tournamentsHref}`)
						? `${tournamentsHref}`
						: `https://${pickleballHref}`;

	const filteredCategories = categories ? categories.filter((category) => category.slug !== 'news') : [];
	const pathSegments = pathname?.split('/').filter((segment) => segment) || [];
	const categorySlug = pathSegments[0];
	const isBlogPathname = pathname && validBlogPathname.test(pathname) && filteredCategories.some((category) => category.slug === categorySlug);
	const switcherOptions = getSwitcherOptions({
		pickleballHref: `${pickleballPrefix}${pickleballHref}`,
		tournamentsHref,
		clubsHref,
		teamLeaguesHref,
		leaguesHref: isLocalHost && isPickleball ? `${leaguesHref}/leagues` : leaguesHref,
	 	wprHref: wprHref && wprHref ? isLocalHost && isPickleball ? `${wprHref}/wpr-rankings` : wprHref : `${pickleballPrefix}${pickleballHref}/wpr-rankings`,
		showTLItem: showTLItem || application === 'team-leagues',
		showWPRItem: showWPRItem || application === 'wpr-rankings'
	})

	const navItems = useMemo(
		() =>
			getNavItems({
				isLocalHost,
				application,
				pickleballHref,
				tournamentsHref,
				clubsHref,
				teamLeaguesHref: showTLItem ? teamLeaguesHref : undefined,
				leaguesHref,
				wprHref
			}),
		[isLocalHost, resolvedApplication, pickleballHref, tournamentsHref, clubsHref, teamLeaguesHref, leaguesHref, wprHref, showTLItem]
	);

	return (
		<header ref={headerRef} className="relative z-50" id="pb-mainheader">
			<div className="relative">
			{isOpen && (
        <div
          className="fixed left-0 right-0 bottom-0 backdrop-blur-sm z-40"
          style={{ top: `${headerHeight}px` }}
          onClick={() => setIsOpen(false)}
        />
      )}
				<Navbar className="sticky top-0 z-50" as="nav" role="navigation">
					<Navbar.Brand
						onClick={toggleDropdown}
						onMouseEnter={() =>
							openDropdown()
						}
						onMouseLeave={() => 
						  closeDropdown()
						}
						className={clsx('select-none !px-0 sm:!pl-4',
							isTournament
								? '!bg-blue-600 before:!bg-blue-600'
								: isTeamLeagues
									? '!bg-[#EF612D] before:!bg-[#EF612D] flex items-center justify-center'
									: resolvedApplication === 'wpr-rankings'
										? '!bg-[#4CA30D] before:!bg-[#4CA30D]'
										: '')
						}
					>
						<HeaderLogo switcherOptions={switcherOptions} headerHeight={headerHeight} application={resolvedApplication} cdnUrl={cdnUrl} isOpen={isOpen} setIsOpen={setIsOpen} />
					</Navbar.Brand>
					<div className="mr-2 sm:mr-6" />

					<Nav
						baseOrigin={baseOrigin}
						application={resolvedApplication}
						pickleballHref={pickleballHref}
						tournamentsHref={tournamentsHref}
						clubsHref={clubsHref}
						teamLeaguesHref={teamLeaguesHref}
						leaguesHref={leaguesHref}
						navItems={navItems}
					/>

					<div className="ml-auto mr-1 flex">
						{user ? (
							<NavbarItem className="!inline-flex sm:!hidden">
								<UserProfile
									user={user}
									canAccessManage={canAccessManage}
									pickleballHref={pickleballHref}
									ssoLink={ssoLink}
									isLocalhost={isLocalHost}
								/>
							</NavbarItem>
						) : (
							<NavbarItem className="!inline-flex sm:!hidden">
								<CustomLink
									href={
										isLocalHost
											? `/login?continue=${encodeURIComponent(baseURL)}`
											: `${ssoLink}/?flow=SelfService&continue=${encodeURIComponent(baseURL)}`
									}
									rel="nofollow"
								>
									Login
								</CustomLink>
							</NavbarItem>
						)}

						<div
							className={`!hidden -skew-x-12 items-center justify-center px-7 text-white sm:!inline-flex ${
								isTournament ? '!bg-blue-600' : isTeamLeagues ? '!bg-[#EF612D] before:!bg-[#EF612D]' : isWPR ? 'bg-[#4CA30D]' : 'bg-experiment'
							}`}
							role="button"
							tabIndex={0}
							data-testid={`nav-pickleball-hub`}
						>
							<div className="skew-x-12">
								<Menu showArrow={false} placement="bottom" offset={24} width={172}>
									<Menu.Trigger asChild>
										<div className="flex cursor-pointer items-center gap-1.5">
											<span className="relative flex text-sm font-semibold text-white transition-all duration-150 hover:text-opacity-80">
												{BLOG_LABEL}
											</span>
											<FontAwesomeIcon icon={faChevronDown} size="sm" />
										</div>
									</Menu.Trigger>
									<Menu.List className="min-w-[172px] !max-w-[172px] !px-0 !py-2">
										{filteredCategories.map((category) => (
											<Menu.Item
												key={category.uuid}
												className="cursor-pointer rounded-lg bg-white text-sm font-medium text-gray-900 hover:bg-[#EBE9FE]"
											>
												<CustomLink
													href={`${pickleballPrefix}${pickleballHref}/${category.slug}/all`}
													className={`line-clamp-1 w-full text-ellipsis px-4 py-2 !outline-none ${
														isBlogPathname && categorySlug === category.slug ? 'underline' : ''
													}`}
												>
													{category.title}
												</CustomLink>
											</Menu.Item>
										))}
									</Menu.List>
								</Menu>
							</div>
						</div>

						<NavbarItem
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
								setSearchShown(!searchShown);
							}}
							className={`!px-3 sm:!px-4 ${isTournament && !searchShown ? 'sm:!border-blue-600' : isTeamLeagues && !searchShown ? 'sm:!border-[#EF612D]' : isWPR && !searchShown ? 'sm:!border-[#4CA30D]' : ''}
								${isTournament && searchShown ? '!border-blue-600 !bg-blue-600' : isTeamLeagues && searchShown ? '!border-[#EF612D] !bg-[#EF612D]' : isWPR && searchShown ? '!border-[#4CA30D] !bg-[#4CA30D]' : ''}`}
							active={searchShown}
							ref={searchRef}
							tabIndex={0}
						>
							<FontAwesomeIcon
								className="sm:!hidden"
								icon={faSearch}
								aria-label="Search"
								title="Search Icon"
								role="button"
								titleId="search-icon-mobile"
							/>
							<FontAwesomeIcon
								className="!hidden sm:!flex"
								size="lg"
								icon={faSearch}
								aria-label="Search"
								title="Search Icon"
								role="button"
								titleId="search-icon-desktop"
							/>
						</NavbarItem>

						{user?.uuid && (
							<div className="flex w-[41px] items-center justify-center sm:hidden">
								<div className="inline-flex items-start sm:hidden">
									<div className="h-5 w-px -skew-x-12 bg-gray-700"></div>
								</div>
								<div className="inline-flex w-full items-center justify-center sm:hidden">
									<NotificationsPopupMobile notifications={notifications} checkoutLink={`${checkoutLink}/pending`} />
								</div>
							</div>
						)}

						<NavbarItem className="!inline-flex items-center !px-3 sm:!hidden" onClick={() => setMobileMenuOpened(!mobileMenuOpened)}>
							<FontAwesomeIcon icon={faBars} />
						</NavbarItem>
					</div>

					{mobileMenuOpened && (
						<div className="fixed inset-0 flex w-full overflow-hidden sm:hidden">
							<div
								aria-hidden="true"
								className="absolute inset-0 bg-white/30 backdrop-blur-md"
								onClick={() => setMobileMenuOpened(false)}
							/>
							<div
								aria-hidden="true"
								className={`absolute right-2 top-2 flex size-11 items-center justify-center rounded-md ${isTournament ? 'bg-blue-500' : 'bg-brand-700'}`}
								onClick={() => setMobileMenuOpened(false)}
							>
								<FontAwesomeIcon icon={faTimes} className="text-white" />
							</div>
							<div className="relative z-50 w-[46%] shrink-0 overflow-auto bg-gray-800">
								<ul className="flex flex-col divide-y divide-gray-900">
									<li className="list-none bg-white">
										<CustomLink
											href={`${pickleballPrefix}${pickleballHref}/watch-now`}
											className="block px-6 py-4 text-sm font-bold leading-5 text-gray-900"
										>
											Watch
										</CustomLink>
									</li>
									{navItems.map((item) => (
										<React.Fragment key={item.id}>
											{item.children === BLOG_LABEL && filteredCategories ? (
												<>
													{filteredCategories.map((category) => (
														<li key={category.uuid} className="list-none">
															<CustomLink
																href={`${pickleballPrefix}${pickleballHref}/${category.slug}/all`}
																className="block px-6 py-4 text-sm font-bold leading-5 text-white"
															>
																{category.title}
															</CustomLink>
														</li>
													))}
												</>
											) : (
												<li className="list-none">
													<CustomLink href={item.href} className="block px-6 py-4 text-sm font-bold leading-5 text-white">
														{item.children}
													</CustomLink>
												</li>
											)}
										</React.Fragment>
									))}
									{(isTournament || isTeamLeagues || isClubs) && (
										<ul className="flex flex-col divide-y divide-gray-900">
											<li className="list-none">
												<CustomLink
													href={`${pickleballPrefix}${pickleballHref}`}
													className="block px-6 py-4 text-sm font-bold leading-5 text-white"
												>
													Pickleball.com
												</CustomLink>
											</li>
										</ul>
									)}
								</ul>
							</div>
						</div>
					)}
				</Navbar>
			</div>
			{searchShown && (
				<GlobalSearch
					searchVisible={searchShown}
					globalSearchButtons={searchButtons}
					toggleRef={searchRef}
					onHide={() => setSearchShown(false)}
					isClubs={isClubs}
					pickleballHref={pickleballHref}
					tournamentsHref={tournamentsHref}
					clubsHref={clubsHref}
					teamLeaguesHref={teamLeaguesHref}
					leaguesHref={leaguesHref}
					application={resolvedApplication}
				/>
			)}
		</header>
	);
}
