import { BLOG_LABEL } from './constants';

export type Application = 'pickleball' | 'tournament' | 'clubs' | 'leagues' | 'team-leagues' | 'wpr-rankings';

export interface NavItem {
	id: string;
	children: string;
	target?: string;
	suffix?: string;
	href: string;
}

export interface SwitcherOptionsInterface {
    app: string;
    label: string;
    img: string;
    bg: string;
    bgDark: string;
    link: string;
  }

interface NavItemsProps {
	isLocalHost: boolean;
	application: Application;
	pickleballHref: string;
	tournamentsHref: string;
	clubsHref: string;
	teamLeaguesHref: string | undefined;
	leaguesHref: string;
	wprHref?: string;
}

interface SwitcherOptionsProps {
  pickleballHref: string;
  tournamentsHref: string;
  clubsHref: string;
  teamLeaguesHref: string;
  leaguesHref: string;
  wprHref: string;
  showTLItem?: boolean;
  showWPRItem?: boolean;
}

export const getSwitcherOptions = ({
    pickleballHref,
    tournamentsHref,
    clubsHref,
    teamLeaguesHref,
    leaguesHref,
    wprHref,
	showTLItem,
	showWPRItem
  }: SwitcherOptionsProps) => {

    const switcherOptions: SwitcherOptionsInterface[] = [
		{ app: "pickleball", label: "Pickleball.com", img: "pickleball-app/assets/images/logo.svg", bg: "bg-experiment", bgDark: "bg-[#471CB1]", link: pickleballHref },
		{ app: "tournament", label: "Tournaments", img: "pickleball-app/header-logos/PT_Abreviated.svg", bg: "bg-blue-600", bgDark: "bg-[#114BBF]", link: tournamentsHref },
		{ app: "leagues", label: "Leagues", img: "pickleball-app/header-logos/PL_Abreviated.svg", bg: "bg-[#F63D68]", bgDark: "bg-[#C53153]", link: leaguesHref },
		...(showTLItem ? [{
		  app: "team-leagues",
		  label: "Team Leagues",
		  img: "pickleball-app/header-logos/PTL_Abreviated.svg",
		  bg: "bg-orange-500",
		  bgDark: "bg-[#CC5425]",
		  link: teamLeaguesHref
		}] : []),
		{ app: "clubs", label: "Clubs", img: "pickleball-app/header-logos/PC_Abreviated.svg", bg: "bg-[#B42318]", bgDark: "bg-[#901C13]", link: clubsHref },
		...(showWPRItem ? [{ app: "wpr-rankings", label: "World Rankings", img: "pickleball-app/header-logos/WPR_Abreviated.svg", bg: "bg-[#4CA30D]", bgDark: "bg-[#3D820A]", link: wprHref }] : [])
	  ];

    return switcherOptions
};

export const getNavItems = ({
	isLocalHost,
	application,
	pickleballHref,
	tournamentsHref,
	clubsHref,
	teamLeaguesHref,
	leaguesHref,
	wprHref = ''
}: NavItemsProps): NavItem[] => {
	const prefix = `http${isLocalHost ? '' : 's'}://`;

	const pickleballNav = [
		{
			id: '1',
			children: 'News',
			href: `${prefix}${pickleballHref}/news/all`,
			suffix: '/news'
		},
		{
			id: '2',
			children: 'Tournaments',
			href: tournamentsHref
		},
		{
			id: '3',
			children: 'Results',
			href: `${prefix}${pickleballHref}/results`,
			suffix: '/results'
		},
		{
			id: '4',
			children: 'Leagues',
			href: isLocalHost ? '/leagues' : leaguesHref,
			suffix: '/leagues'
		},
		...(teamLeaguesHref
			? [
					{
						id: '5',
						children: 'Team Leagues',
						href: teamLeaguesHref
					}
				]
			: []),
		{
			id: '6',
			children: 'Clubs',
			href: clubsHref
		},
		{
			id: '7',
			children: 'Players',
			href: `${prefix}${pickleballHref}/players`,
			suffix: '/players'
		},
		{
			id: '8',
			children: 'Rankings',
			href: `${prefix}${pickleballHref}/rankings`,
			suffix: '/rankings'
		},
		{
			id: '9',
			children: 'Tickets',
			target: '_blank',
			href: 'https://www.tixr.com/groups/ppa'
		},
		{
			id: '10',
			children: BLOG_LABEL,
			href: `${prefix}${pickleballHref}/news/all`
		}
	];
	
	const wprNav = [
		{
			id: '1',
			children: 'Home',
			href: isLocalHost ? '/wpr-rankings' : wprHref
		},
		{
			id: '2',
			children: 'Explore rankings',
			href: isLocalHost ? '/wpr-rankings/rankings' : `${wprHref}/rankings`,
			suffix: '/rankings'
		},
		{
			id: '3',
			children: 'How Does WPR Work',
			href: isLocalHost ? '/wpr-rankings/how-does-wpr-work' : `${wprHref}/how-does-wpr-work`,
			suffix: '/how-does-wpr-work'
		}
	];

	const nonPickleballNav = [
		{
			id: '1',
			children: 'News',
			href: `https://${pickleballHref}/news/all`
		},
		{
			id: '2',
			children: 'Tournaments',
			href: tournamentsHref,
			suffix: application === 'tournament' ? '/tournaments' : undefined
		},
		{
			id: '3',
			children: 'Results',
			href: `https://${pickleballHref}/results`
		},
		{
			id: '4',
			children: 'Leagues',
			href: leaguesHref
		},
		...(teamLeaguesHref
			? [
					{
						id: '5',
						children: 'Team Leagues',
						href: teamLeaguesHref,
						suffix: application === 'team-leagues' ? '/team-leagues' : undefined
					}
				]
			: []),
		{
			id: '6',
			children: 'Clubs',
			href: clubsHref,
			suffix: application === 'clubs' ? '/clubs' : undefined
		},
		{
			id: '7',
			children: 'Players',
			href: `https://${pickleballHref}/players`
		},
		{
			id: '8',
			children: 'Rankings',
			href: `https://${pickleballHref}/rankings`
		},
		{
			id: '9',
			children: 'Tickets',
			target: '_blank',
			href: 'https://www.tixr.com/groups/ppa'
		},
		{
			id: '10',
			children: BLOG_LABEL,
			href: `https://${pickleballHref}/news/all`
		}
	];

	return application === 'pickleball'
		? pickleballNav
		: application === 'wpr-rankings'
			? wprNav
			: application === 'tournament' || application === 'clubs' || application === 'team-leagues'
				? nonPickleballNav
				: [];
};
